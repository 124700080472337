import _ from 'lodash'

const menu = [
  {
    title: 'Dashboard',
    route: {name: 'BoDashboard'},
    menu: 'BoDashboard',
    icon:  'icon-grid',
  },
  {
    title: 'Report Statistics',
    icon:  'icon-speedometer',
    children: [
      { 
        title: 'Project Statistics', 
        route: {name: 'BoReportStatistics'},
        menu: "BoReportStatistics"
      },
      { 
        title: 'Financial Report', 
        route: {name: 'BoReportFinancial'},
        menu: "BoReportFinancial"
      },
    ]
  },
  { cap: 'Transaction', relate: ['BoProspect', 'BoCustomer', 'BoAfterSales', 'BoSurveySchedule', 'BoInOutCash'] },
  {
    title: 'Prospects',
    route: {name: 'BoProspect'},
    menu: 'BoProspect',
    icon:  'ti-briefcase',
  },
  {
    title: 'Customers',
    route: { name: 'BoCustomer' },
    menu: 'BoCustomer',
    icon: 'icon-user',
  },
  {
    title: 'Survey Schedules',
    route: { name: 'BoSurveySchedule' },
    menu: 'BoSurveySchedule',
    icon: 'ti-briefcase',
  },  
  {
    title: 'After Sales Service',
    route: { name: 'BoAfterSalesService' },
    menu: 'BoAfterSalesService', // Ngikutin di model UserLevel
    icon: 'ti-bookmark-alt',
    meta: {
      pageTitle: 'After Sales Service'
    }
  },
  {
    title: 'In Out Cash',
    route: { name: 'BoInOutCash' },
    menu: 'BoInOutCash',
    icon: 'ti-direction-alt',
    meta: {
      pageTitle: 'In Out Cash'
    }
  },
  { cap: 'Others', relate: ['BoFeedback', 'BoAssignment'] },
  {
    title: 'Assignment',
    route: { name: 'BoAssignment' },
    menu: 'BoAssignment',
    icon: 'ti-clipboard',
  },
  { cap: 'Master Data', relate: ['BoPartner', 'BoTarget', 'BoExpense', 'BoSurveyor', 'BoPosition', 'BoProspectCategory'] },
  {
    title: 'Master Data',
    icon: 'ti-view-list-alt',
    children: [
      { title: 'Surveyor', route: { name: 'BoSurveyor' }, menu: 'BoSurveyor',},
      { title: 'Prospect Category', route: { name: 'BoProspectCategory' }, menu: 'BoProspectCategory',},
      { title: 'Position', route: { name: 'BoPosition' }, menu: 'BoPosition',},
      { title: 'Partner', route: { name: 'BoPartner' }, menu: 'BoPartner',},
      { title: 'Target', route: { name: 'BoTarget' }, menu: 'BoTarget',},
    ],
  },
  {
    title: 'Expense',
    menu: 'BoExpense',
    icon: 'icon-wallet',
    route: {name: 'BoExpense'}
  },
  {
    cap: 'Settings',
    relate: ['BoWebSetting', 'Users', 'BoUserLevel', 'BoAuditTrail']
  },
  {
    title: 'App Settings',
    icon: 'icon-settings',
    route: {name: 'BoWebSettings'},
    menu: 'BoWebSetting',
  },
  {
    title: 'User Management',
    icon: 'icon-user-follow',
    children: [
      {
        title: 'User List',
        route: {name: 'Users'},
        menu: 'Users',
      },
      {
        title: 'User Level',
        route: {name: 'BoUserLevel'},
        menu: 'BoUserLevel'
      }
    ],
  },
  {
    title: 'Audit Trail',
    route: { name: 'BoAuditTrail' },
    icon:  'icon-check',
    menu: 'BoAuditTrail'
  }
]

let nav = menu

const accessAll = localStorage.getItem('u_menu_all')
if (accessAll == 'N') {
  let menuRole = JSON.parse(localStorage.getItem('u_menu') || '[]')
  menuRole = _.map(menuRole, v => {
    return v.menu
  })
  
  nav = _.filter(nav, v => {
    if (v.cap) {
      return _.filter(v.relate, v2 => {
        return menuRole.indexOf(v2) > -1
      }).length >= 1
    } 
    else if (v.children) {
      return _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      }).length >= 1
    } 
    else {
      return menuRole.indexOf(v.menu) > -1
    }
  })

  nav = _.map(nav, v => {
    if (v.children) {
      v.children = _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      })
    }

    return v
  })
}

export default nav